.rbc-event, .rbc-day-slot .rbc-background-event {
    border-radius: 60%;
    border: none;
    color: black;
    text-align: center;
    background-color: transparent;
    background-image: url(./img/mark2.png);
    background-position: center; 
    background-size: 95%;
    background-repeat: no-repeat;
    margin-left: 3px;

  }
  
  
  .rbc-month-view {
    border: none;
  }

  .rbc-month-header {
    display: none;
  }

  .rbc-today {
    background-color: #eee;
  }
  
  .rbc-date-cell {
    font-size: 20px;
  }

  

.rbc-row {
    height: 0;
    font-size: 20px;
}

.rbc-event-content {
    visibility: hidden;
}

.rbc-event.rbc-selected {
    background-color: transparent;
}


.rbc-day-bg + .rbc-day-bg {
   border-left: none
}

.rbc-off-range-bg {
  background: transparent;
}

.rbc-off-range {
  visibility: hidden;
}

.rbc-month-row + .rbc-month-row {
   border-top: none; 
}

.rbc-month-row + .rbc-month-row {
   border-top: none; 
}

